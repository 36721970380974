import React from 'react';
import tutorial1Png from './TutorialPage1.jpg';
import tutorial2Jpg from './TutorialPage2.jpg';
import tutorial3Jpg from './TutorialPage3.jpg';
import tutorial4Jpg from './TutorialPage4.jpg';
import headphonesNotDetectedJpg from './TutorialHeadPhonesNotDetected.jpg';
import fxOptionsSheetJpg from './FXOptionsSheet.jpg';

import './App.css';

const AudioInfo = () => (
    <div className='audioInfo'>
      <h1>User Manual</h1>
      <p><strong>LoopStar is better with Headphones</strong> This will cut-down on <strong>echos & feedback</strong> and closed-eared headphones will cut down on audio bleed into your recorded loops.</p>
      <p>&nbsp;</p>
      <p><strong>(1)</strong> LoopStar input volume is controlled by the <strong>Mic Vol</strong> slider.  Adjust the input volume such that you get a good input signal.  </p>
      <p>&nbsp;</p>
      <p><strong>(2)</strong> The <strong>Record</strong> button: 
      <p>&nbsp;&nbsp;<strong>Method (a) "Natural" loop recording:</strong> </p>Tap the Record button once to start the loop, and then tap again to stop the loop.  
      At this point the loop will begin looping, and LoopStar will calculate the Beats Per Minute (BPM) of the loop, the Record button will turn into a Stop/Play button, and the <strong>(3)</strong> delete button will be shown.</p>
      <p>  
      <p>&nbsp;&nbsp;<strong>Method (b) "Sequencer Metronome" loop recording:</strong> </p>Assuming your loop is in 4/4 time, then you can select <strong>Sequencer cells (4)</strong> and start the <strong>Play Sequencer (5)</strong>.   
      If the sequencer is already playing, then LoopStar will record your LoopTrack to a predetermined length that will be perfectly chopped to match the BPM of the song. 
      Select a <strong>Loop Multiplier value (8)</strong> (2) to double, (4) to quadruple, or (8) to octuple the target recording length of the loop, as well as show you the target length of the loop track.</p>
      <p><strong>(6) Play All Track Loops and Sequencer</strong> once you have a loop recorded, the <strong>Play All</strong> button becomes enabled, and you can use this to start all Track Loops 1-8, the drum pad Sequencer and Sampler in sync.</p>
      <p>&nbsp;</p>
      <img src={tutorial1Png} alt="LoopStar Tutorial: 1 of 4" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      <img src={headphonesNotDetectedJpg} alt="Headphones not detected" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      <p><strong>(7) Microphone Button</strong> If don't have headphones connected, and you have any issue with feedback or noise you can hit the mic button to mute monitoring.  
      With some thunderbolt connected devices such as with the <strong>iRig HD 2</strong> the headphones will not be detected.  So it would best to select "No enable Speaker output"  If this is a configuration you will use long term, then select "do not ask again".  
      If you are not using headphones or a connected device you can mute monitoring to avoid creating feedback.
      </p>
      <p>&nbsp;</p>
      <img src={tutorial2Jpg} alt="LoopStar Tutorial: 2 of 4" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      
      <p><strong>Loop Multiplier value (8)</strong> (1) Cuts the recording loop for the selected track to the exact duration calculated by the Sequencer while it is running.  Selecting (2) double the duration, (4) quadruples the duration, or (8) octuples the target recording length of the loop.  
      When the square [1] for the Loop Multiplier is in single shot mode, and will record on that track until you hit the stop button.  As you select the Loop Multiplier button it will show you the target length of the loop track.</p>
      <p>&nbsp;</p>
      <p><strong>(9) Folder Icon - Import Audio</strong> Import Audio tracks from you device or iCloud or other LoopStar sessions.   </p>
      <p>&nbsp;</p>
      <p><strong>(10) Pan and Vol sliders</strong> Adjust the Volume and Left-Right Stereo Panning for each track</p>
      <p>&nbsp;</p>
      <img src={tutorial3Jpg} alt="LoopStar Tutorial: 3 of 4" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      <p><strong>(11) The FX button</strong> Access Audio effects options:  Save your current Mic/Input effects to a preset; 
        Select a Mic/Input Preset; Adjust effects for: Mic/Input, LoopTracks 1-3, Drum Pads, and Keyboards.  
        Also access Tempo Effects which will tailor time-based audio effects to the tempo of the song. </p>
      <p>&nbsp;</p>
      <p><strong>(12) The Mode button</strong> Toggle between different features: Sequencer, Pads, Loop Player Tracks: 4, 5, 6, 7 & 8, and the Sampler.</p>
      <p>&nbsp;</p>
      <p><strong>(13) Master Audio Track</strong> Record your performance to an .mp4 Master audio track. 
      Get your loops and beats going, hit Record on the Master track button and your Mic input will be recorded along with any stops and starts of various loops and sequencer cell changes.  
      This is a great way to quickly express yourself over your loops and hone your ideas for a later listen.  
      Note: with the Session Browser under the File folder (16), you have the option to play all of your recorded Master Tracks.  
      So for example, after you've recorded a dozen loop sessions to your iPhone, you can easily listen to them back and pick out the best ideas for more work.</p>
      <p>&nbsp;</p>
      <p><strong>(14) Drum Pad Sound options</strong> Beside the Default Kit, get access to the following Kits: 808, Hip Hop, Jazz, RnB, and Rock.  </p>
      <p>&nbsp;</p>
      FX Options:
      <p>&nbsp;</p>
      <img src={fxOptionsSheetJpg} alt="FX Options Sheet" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      <p><strong>(15) Settings Button</strong> Access Advanced options; Enter Song info; Set an exact tempo; dial in your drum pad mix and more.   </p>
      <p>&nbsp;</p>
      <p><strong>(16) File Folder Icon</strong> Save Session, Create New Session, Load Session, Save to iCloud, Import Audio tracks from your device or iCloud or other LoopStar sessions.  
      Browse your Session Files and access the audio editor.  Play all master audio tracks.  </p>
      <p>&nbsp;</p>
      <p><strong>(17) Record Video</strong> Simultaneously record a video and Master Audio Track as you perform with LoopStar.  
      Record video as you play your loop tracks, if you have a MIDI foot-pedal, you can play/stop Tracks 1-3, and the Sequencer to add dynamic audio changes to your video & audio performance.  
      Quickly create content that can be uploaded to your favorite social media platform via .m4a, .m4v, and .mov audio and video files.  With a pro-subscription, add watermarks to your video to your website, song-title and artist name (via the profile tab). </p>
      <p>&nbsp;</p>
      <img src={tutorial4Jpg} alt="LoopStar Tutorial: 4 of 4" width={300} height={650} style={{ alignSelf: 'center' }} />
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h1>Audio Engineering Basics</h1>
      <p>Audio Engineering Terms: <strong>Glossary</strong></p>
      <text className="App-text">
      <p></p>
          <a>
          LoopStar is a multi-track recorder with a focus on loops. Multi-track recording is a method of sound recording that permits you to record multiple sound sources to separate tracks and then combine them into a whole. 
          For more on multitrack recording, see: 
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Multitrack_recording"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Multitrack_recording 
        </a>
          <p></p>
          LoopStar records it’s digital audio in 44.1K format, using audio clips stored in standard audio file formats (.caf, .wav, .mp4) and played back on MP3 players, smartphones, and computers. 
          For more on digital audio, see:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Digital_audio"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Digital_audio 
        </a>
          <p></p>
          Hip hop audio production is the recording of hip hop music in a studio. The essential elements of hip hop production are drum beats, samplers, synthesizers, multitrack recorders and turntables. 
          LoopStar brings the ethos of Hip hop audio production in a Loop-centric recording format to your iOS Device. For more on hip hop audio production, see:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Hip_hop_production"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Hip_hop_production 
        </a> 
          <p></p>
          The Roland TR-808, commonly referred to as the 808, is a drum machine crafted by the Roland Corporation in the early 80s. Users of the 808 can program rhythms instead of using samples. 
          For more on the 808, see:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Roland_TR-808"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Roland_TR-808 
        </a> 
          <p></p>
          LoopStar V1.0 comes with an 8-step-sequencer, using a grid of 8 steps with 5 instrument rows.  
          For more on step Sequencers see:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Music_sequencer#Step_sequencers"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Music_sequencer#Step_sequencersç 
        </a>  

        <p></p>
          A music sequencer has the capacity to record and edit multitrack audio and to play back music. Sequencers that are used for audio recording are known as digital audio workstations (DAWs) For more on music sequencers, see:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Music_sequencer"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Music_sequencer 
        </a> 
          <p></p>
          A synthesizer is an electronic musical instrument that produces audio signals using various forms of analog and digital sound synthesis. Synthesizers generally are controlled via sequencers, software, keyboards, or other instruments. For more on synthesizers, see here: 
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Synthesizer"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Synthesizer  
        </a> 

        <p></p>
          A Digital Audio Workstation, or DAW, is application software or an electronic device that is used to record, edit, and produce audio files. They can be used to produce and record almost any complex recorded audio such as music, podcasts, soundtracks, speech, and sound effects. For more on DAWs, see here: 
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Digital_audio_workstation"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Digital_audio_workstation  
        </a> 
          <p></p>
          A drum machine is a type of electronic instrument that replicates drum beats, patterns, and percussion sounds. They can produce both synthesized electronic tones and imitate drum kits and other analog percussion instruments. For more on drum machines, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Drum_machine"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Drum_machine
        </a> 
          <p></p>
          A guitar amplifier is an electronic device that strengthens the electrical signal from a pickup on an electric, acoustic, or bass guitar. By strengthening this weak electrical signal, an amplifier can produce sound through a loudspeaker. Standalone amplifiers require a separate speaker, while “combo” amps contain both the amplifier and one or more speakers. For more on guitar amplifiers, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Guitar_amplifier"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Guitar_amplifier
        </a> 
          <p></p>
          An audio effects unit, often in the form of a pedal, is an electronic device that alters the sound of a musical instrument by means of audio signal processing. Common effects from an effects unit include distortion, wah-wah, pitch-shift, reverb and delay. For more on effects units, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Effects_unit"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Effects_unit
        </a> 
          <p></p>
          Reverberation is the continuation of a sound after it has been produced. Electronic sound reverberation is designed to replicate the frequencies of sound made when a person sings, talks, or plays an instrument in a room with sound-reflective surfaces. For more on reverberation, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Reverberation"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Reverberation
        </a> 
          <p></p>
          Delay is an audio effects unit that records an input signal and then plays it back after a certain amount of time. A delay can be played back multiple times or it can be used to create a sound like an echo. For more on delay, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Delay_(audio_effect)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Delay_(audio_effect)
        </a> 
          <p></p>
          Pitch shifting is a recording technique in which a sound’s original pitch is either raised or lowered. Pitch shifting can be accomplished using an audio effects unit called a pitch shifter. For more on pitch shift, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Pitch_shift"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Pitch_shift
        </a> 
          <p></p>
          Distortion and overdrive are types of audio signal processing that change the sound of amplified electric instruments (such as the electric guitar) by increasing their gain. This alteration usually results in a “fuzzy” or “gritty” sound and can be heard in many music genres, such as hard rock, heavy metal and punk rock. For more on distortion, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Distortion_(music)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Distortion_(music)
        </a> 
          <p></p>
          Modulation is a method of changing sound properties to evoke a sense of dynamic movement and depth in an audio recording. A device that performs modulation, known as a modulator, can conduct a signal through a variety of sound effects. For more on modulation, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Modulation"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Modulation
        </a> 
          <p></p>
          The chorus effect is the phenomenon whereby a collection of individual sounds with very similar pitches are heard as one. The chorus effect, as heard naturally in a choir or a string orchestra, can be reproduced electronically by means of an effects unit or signal processing device. For more on the chorus effect, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Chorus_effect"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Chorus_effect
        </a> 
          <p></p>
          Flanging is an audio effect created by combining two signals, with one signal delayed by a very brief and slowly changing period. Flanging creates a swept comb filter effect wherein a “swooping” or “peaking” sound is achieved. The type of effects unit that achieves this sound is called a flanger. For more on flanging, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Flanging"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Flanging
        </a> 
          <p></p>
          A phaser is an electronic sound processor that filters a sound by creating peaks and troughs in the frequency spectrum. Phasers can be used on natural sounds such as the human voice to create a synthesized or electronic quality to that sound. For more on phasers, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Phaser_(effect)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Phaser_(effect)
        </a> 
          <p></p>
          Equalization is a method of adjusting the balance between frequency components in an electronic signal. Equalization is used in sound recording to adjust the response of loudspeakers, microphones, and instrument pickups, or to eliminate unwanted background noise such as the hum from an amplifier. For more on equalization, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Equalization_(audio)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Equalization_(audio)
        </a> 
          <p></p>
          A wah-wah pedal is an electric guitar effects pedal that changes the tone and frequency of guitar signals to create a distinctive “wah-wah” sound. This sound effect originated in the 1920s when it was created by trombone and trumpet players, who used a mute in the instrument’s bell: it was then replicated for guitar by using a rocking foot pedal that alters the guitar signal via electronic circuitry. For more on wah-wah pedals, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Wah-wah_pedal"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Wah-wah_pedal
        </a> 
          <p></p>
          A mixing console is an electronic device that combines the sounds of many different audio signals. Depending on the console, the device can be used to control analog or digital signals. For more on mixing consoles, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Mixing_console"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Mixing_console
        </a> 
          <p></p>
          Panning distributes a sound signal into a new stereo or multi-channel sound field and can be used to create the impression of a source moving from one side of the soundstage to the other. The analog sliders and knobs used to create this effect are known as “pan pots” (short for “panning potentiometer”); in digital software, pan pots are represented by onscreen virtual knobs and sliders. For more on panning, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Panning_(audio)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Panning_(audio)
        </a> 
          <p></p>
          Stereophonic sound, commonly known as stereo, is a process of sound production that creates the naturalistic impression of sounds coming from many directions. This contrasts with monophonic or “mono” sound, where audio seems to come from one direction. For more on stereophonic sound, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Stereophonic_sound"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Stereophonic_sound
        </a> 

        <p></p>
          A microphone preamplifier is a sound engineering device that increases a microphone’s signal by providing stable gain. Microphone signals are otherwise too weak to be transmitted to recording devices or mixing consoles, so a microphone preamplifier functions as the “middle man” between these elements. For more on microphone preamplifiers, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Microphone_preamplifier"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Microphone_preamplifier
        </a> 
          <p></p>
          Frequency (in audio) is a periodic vibration that is typically audible to the average human ear. Frequency is an important tool for measuring vibratory phenomena such as audio signals while recording music. For more on frequency, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Frequency"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Frequency
        </a> 
          <p></p>
          Fade is the increase or decrease in the level of an audio signal. This typically manifests as a fade-in or fade-out of volume level in a recorded piece of music. Fading-out is a common resolution to a song that has no clear conclusion. For more on fade, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Fade_(audio_engineering)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Fade_(audio_engineering)
        </a> 
          <p></p>
          A loop is a repeating segment of sound material. Short sections can be used to create a recurring pattern, while longer sections can be used as a means of accompaniment for a single player. Many types of digital technology can be used to play loops, such as digital samplers, synthesizers, sequencers, drum machines, and turntables. They can also be programmed using computer software. For more on loops, see here:
          <a
          className="App-link"
          href="https://en.wikipedia.org/wiki/Loop_(music)"
          target="_blank"
          rel="noopener noreferrer"
        > https://en.wikipedia.org/wiki/Loop_(music)
        </a> 
          <p></p>
          AudioKit (open source on GitHub) is a swift audio synthesis, processing, and analysis platform for iOS, macOS and tvOS. For more on AudioKit, see here:
          <a
          className="App-link"
          href="https://github.com/AudioKit/AudioKit"
          target="_blank"
          rel="noopener noreferrer"
        > https://github.com/AudioKit/AudioKit
        </a> 
          <p></p>
          AudioKit 4.11 References:
          <a
          className="App-link"
          href="https://audiokit.io/docs/"
          target="_blank"
          rel="noopener noreferrer"
        > https://audiokit.io/docs/
        </a> 
          <p></p>
          Thank you AudioKit contributors:
          <a
          className="App-link"
          href="https://github.com/AudioKit/AudioKit/graphs/contributors"
          target="_blank"
          rel="noopener noreferrer"
        > https://github.com/AudioKit/AudioKit/graphs/contributors
        </a>


        </a>



          <p></p>
          <p></p>
          <p></p>
          </text>
    </div>
  );

  export default AudioInfo;