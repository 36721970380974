import React from 'react';
import './App.css';
import { NavLink, Switch, Route } from 'react-router-dom';
import AudioInfo from './AudioInfo';
import Home from './Home';
import Gear from './Gear';
import Policy from './Policy';

const App = () => (
  <div className='app' >
    <a 
          className="App-link"
          href="https://apps.apple.com/us/app/loopstar/id1543974483"
          target="_blank"
          rel="noopener noreferrer"
        >
           Get LoopStar on the AppStore
        </a>
        
        <p></p>
        <p></p>
    <Navigation />
    <Main />
   </div>
);

const Navigation = () => (
  <nav>
    <ul>
      <li><NavLink exact activeClassName="current" to='/'>Home</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/Tutorials'>Tutorials</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/gear'>Gear</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/audioInfo'>Manual</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/contact'>Contact</NavLink></li>
      <li><NavLink exact activeClassName="current" to='/Policy'>Policy</NavLink></li>
    </ul>
  </nav>
);



const Tutorials = () => (
  <div className='tutorials'>
    <h1>LoopStar Tutorials</h1>
    <p> You can get started creating vocal loops with just a pair of headphones:</p>
      <a className="App-link"
        href="https://youtu.be/UFjGJSMCo3g" target="_blank" rel="noopener noreferrer"
      > LoopStar Tutorial 1: Using Headphones with LoopStar for vocal looping with effects and sequence  </a>
      <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
      <p>This tutorial goes through a full demo:</p>
      <a className="App-link"
         href="https://youtu.be/UFI4qlhryOk" target="_blank"  rel="noopener noreferrer"
        > LoopStar guitar looping Demo, using MIDI Foot controller, and audio effects </a>
      <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
      <p>With LoopStar you can import Audio Samples and edit them (with a Pro Subscription):</p>
      <a className="App-link"
         href="https://www.youtube.com/watch?v=WUxzrxAG2UQ" target="_blank"  rel="noopener noreferrer"
        > LoopStar App Edit Drum Sample and do guitar Looping Demo </a>
      <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
      <p>Access a MIDI suite of guitar pedal effects with LoopStar (with a Pro Subscription):</p>
      <a className="App-link"
         href="https://youtu.be/mumOEqaCEt0" target="_blank"  rel="noopener noreferrer"
        > LoopStar Audio Effects Demo using the FCB1010 MIDI foot controller</a>
        <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
      <p>Record your audio effects, and access loops through a foot controller as your record your video in LoopStar:</p>
      <a className="App-link"
         href="https://youtu.be/i7C9kUPH_Ek" target="_blank"  rel="noopener noreferrer"
        > BeatsAndGuitar with LoopStar.app using an early version of the Video recording feature</a>
          <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
  </div>
);



const Contact = () => (
  <div className='contact'>
    <h1>LoopStar App Contact info</h1>
    <p>You can reach me via email at: <strong>loopstarapp@gmail.com</strong></p>
    <h1>About LoopStar</h1>
    <a className="App-link"
         href="https://www.youtube.com/channel/UCDeRM6nXwf_BG3UFeAJhI5A" target="_blank"  rel="noopener noreferrer"
        > LoopStarApp on YouTube </a>
        <p></p>
     <a className="App-link"
        href="https://www.instagram.com/loopstarapp/" target="_blank" rel="noopener noreferrer"
      > LoopStarApp on Instagram  </a>
      <p></p>
      <a className="App-link"
         href="https://twitter.com/LoopStarApp1" target="_blank"  rel="noopener noreferrer"
        > LoopStarApp on Twitter </a>
        <p></p>
        <a className="App-link"
         href="https://www.facebook.com/loopstarapp" target="_blank"  rel="noopener noreferrer"
        > LoopStarApp on Facebook </a>
        <p></p>
        <a className="App-link"
         href="https://bandcamp.com/loopstarapp" target="_blank"  rel="noopener noreferrer"
        > LoopStarApp on BandCamp </a>
  </div>
);

const Main = () => (
  <Switch>
    <Route exact path='/Policy' component={Policy}></Route>
    <Route exact path='/gear' component={Gear}></Route>
    <Route exact path='/tutorials' component={Tutorials}></Route>
    <Route exact path='/audioInfo' component={AudioInfo}></Route>
    <Route exact path='/contact' component={Contact}></Route>
    <Route exact path='/' component={Home}></Route>
  </Switch>
);

export default App;