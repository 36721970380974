import React from 'react';
import { NavLink } from 'react-router-dom';
import lsseq from './lssequencer300x650.jpg';
import './App.css';

const Home = () => (
    <div className='home'>
      
      
      <h1>Welcome to LoopStar</h1>
      <p></p>
      <NavLink exact activeClassName="current" to='/Policy'>Policy</NavLink>
      <header className="App-header">
      <a
          className="App-link"
          href="https://apps.apple.com/us/app/loopstar/id1543974483"
          target="_blank"
          rel="noopener noreferrer"
        >
           LoopStar on the AppStore
        </a>
        <p></p>
        <p></p>
        <p>
          &nbsp;&nbsp;
        <a
          className="App-link"
          href="https://www.youtube.com/channel/UCDeRM6nXwf_BG3UFeAJhI5A"
          target="_blank"
          rel="noopener noreferrer"
        >
           LoopStar YouTube Channel
        </a>
        &nbsp;&nbsp;
        </p>
        
        <p></p>
        
        </header>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p>&nbsp;</p>
        
      <body className="App-subHeader">
      
      <p><a>What is LoopStar?</a></p>
        </body>
        <text className="App-text">
        <a> LoopStar 1.0 is a powerful new iPhone & iPad Audio Loop and Video Recorder App:</a>
        <a> Record loops and create beats with audio effects, and record a master audio and video tracks to share with the world.</a>
        <a> LoopStar is a new amazing way to start your music creation.  With LoopStar you can create tight little audio clips and tracks right from your iPhone.  
        </a>
        <p></p><a>
          LoopStar has it's own sequencer, drum sounds, mixing and effects, so you can quickly put together your own unique loops.  
        </a><a>
          LoopStar was written by the guitarist of Beats and Guitar:&nbsp;
          
          <a
          className="App-link"
          href="https://open.spotify.com/track/5ta2GPL0OefWxdp7s91z7u"
          target="_blank"
          rel="noopener noreferrer"
        >BeatsAndGuitars - A Lil Bit Higher 
        </a> who has been looping for decades and after discovering the power of AudioKit decided to build a Loop App from scratch that he's always wanted.  
        <p></p>
          With LoopStar plug your guitar right in using an iRig or other audio interface:&nbsp;
          <NavLink exact activeClassName="current" to='/gear'>IK Multimedia iRig HD 2 digital guitar interface</NavLink>
            or with a mic-headphone jack:&nbsp; 
            <NavLink exact activeClassName="current" to='/gear'>Movo TCB2 XLR Microphone to TRRS Smartphone Adapter with Headphone Jack - Compatible with iPhone and Android - XLR Female to 3.5mm Male Y Splitter Mic and Audio Adapter, Must-Have Cable for Recording</NavLink>
          &nbsp; And then you can make great sounding loops and audio tracks directly from your guitar to your iPhone.
        <p></p>
        There are other low cost iPhone - iPad interfaces to get your guitar or keyboard into your device:&nbsp;
        <NavLink exact activeClassName="current" to='/gear'>Guitar Effects Interface Adapter Converter Link Devices for iPhone iPod and iPad</NavLink>
        &nbsp;
          With a Pro Subscription access more than just Reverb: Pitch-Shifter, Delay, Guitar Amp, Tremolo, Phaser, Chorus and more!
        </a>
        
        <a>With a Pro Subscription, save directly to iCloud and you can upload your Audio Session as a basis for your creative expression in other DAWs like Logic.  
             </a>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        </text>
        <body className="App-subHeader">
          Record Loops - There are two ways of going about creating a loop:  
        </body>
        <text className="App-text">
          <a>
          (1) Hit record on track1-3, once at the beginning of the loop, and then once at the end. 
          </a><a> Or</a>
          (2) Start the sequencer, make a drum beat and use it as a metronome, then set the number of bars your loop is and hit record at the start of the loop.
          <p></p>
          <p></p>
          <img src={lsseq} alt="LoopStar Sequencer" width={300} height={650} style={{ alignSelf: 'center' }} />
          <p></p>
        </text>
        <body className="App-subHeader">
          <p></p>
          <p></p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          Create a beat: 
        </body>
        <text className="App-text">
          <a>
          (1) Hit play on the sequencer or hit the play all button to start the sequencer
          <p></p>
          (2) Press on a cell in the sequencer to activate the Kik, Snr, HH (Hi-Hat), HO (Hi-Hat-Open), or Clp (Clap) 
          <p></p>
          (3) Change the BPM to find the right tempo for your music.  Or hit the Tap Tempo button several times to set the BPM
          <p></p>
          (4) Dynamically add and subtract cells as the sequencer plays until you find a beat you like 
          <p></p>
          (5) With Pro Subscription: Select from different Pads: Default (Pop), 808, Hip-Hop, Jazz, RnB, & Rock
          <p></p>
          (6) Hit Tuner - with Pro Subscription: Long-Press on Kik, Snr, HH, HO, or Clp button to adjust the tuning of the drum hit.
          <p></p>
          (7) Trap-matic - with Pro Subscription: Long-Press on Sequence cell and add fast 64th note hits to your beat.
          </a>
          <p></p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </text>
        <body className="App-subHeader">
          Select Effects for your mic-input: 
        </body>
        <text className="App-text">
          <a>
          (1) Select "fx" and you will get a list of LoopStar Effects that can be applied to Mic/Input, Tracks 1-3, Pads, Keyboard and Tempo Effects.
          <p></p>
          (2) Select "Mic / Input" and you will have Reverb with mix, gain, minDelayTime, maxDelayTime, decayTimeAt0Hz, randomizeReflections & etc.  
           Adjust until you find a sound you like that goes with your instrument or voice.
          <p></p>
          (3) If you have a LoopStar.pro subscription, then access PitchShift, RhinoGtr, Chorus, Reverb, Delay, Compressor, Tremolo, Equalizer, Phaser, and Flanger.
          <p></p>
          (4) If you have a LoopStar.pro subscription, then from "fx" select "Input Effect Presets" and select a preset.
          <p></p>
          (5) If you have a LoopStar.pro subscription, after you have the input effect that you like dialed in, then from "fx" select "Input Effect Presets" and select "Save Current Input Effects" to save your preset.
          </a>
          <p></p>
          <p></p>
          <p></p>
        </text>
        <body className="App-subHeader">
          Mixing Options in LoopStar
        </body>
        <text className="App-text">
          <a>
          (1) Pan controls the Panning focus your sound either to the left or the right.  Vol controls the Volume of sound for a particular track.  
              "S-Vol" denotes the sequencer volume.  "M-Vol" denotes the Master Track volume.  Adjust until you find a sound you like that goes with your instrument or voice.
          <p></p>
          (2) With a LoopStar.pro subscription you have access to Tracks 4-8, where you can adjust the pan and volume of those tracks while in the Player mode. 
          <p></p>
          (3) Under the options gear, you can select the Pad Mixer.  Here you can adjust the panning and volume for the Kik, Snr, HH (high hat), HO (hat open), and Clp (Clap)
          <p></p>
          (4) Selecting the Master Record option (the red button towards the bottom center of the screen) records all loops and instruments into one master track, be sure to carefully set the levels before you start your master recording.
          <p></p>
          </a>
          <p></p>
          <p></p>
          <p></p>
        </text>

    </div>
  );

  export default Home;