import React from 'react';
import './App.css';


const iframeIAdapt = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&region=US&placement=B0863B5FBJ&asins=B0863B5FBJ&linkId=fd59f72fe84688e0f44e8cced6395fd2&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeIAdapt2 = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&region=US&placement=B0855SCRKX&asins=B0855SCRKX&linkId=405da50316ed305fd175d3d80b20e4b9&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeMovoXLR = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&region=US&placement=B01DTM8SJA&asins=B01DTM8SJA&linkId=d67d8354da18d9086eb5c3ca4b752f0e&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeiRigHD2 = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&region=US&placement=B01KPYGBR6&asins=B01KPYGBR6&linkId=abe5922a96b11db8260428717555e294&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframe2Oct = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&region=US&placement=B07YCB8K18&asins=B07YCB8K18&linkId=ffc7d207890636b6eb746bf1736ca31e&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeSmartRig = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&amp;region=US&placement=B075HZ8RTK&asins=B075HZ8RTK&linkId=20d752622f2bbbc4e036dda7020b1f47&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeiRigBlueBoard = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&amp;region=US&placement=B00BIPL9JW&asins=B00BIPL9JW&linkId=db25520aea3557a22027f16330af3de6&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeWirelessBTAdapterBMA01 = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&amp;region=US&placement=B085DQYW8B&asins=B085DQYW8B&linkId=d0ba786c5a018024afbdf446ac859ab4&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeBehringerFCB1010 = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&amp;region=US&placement=B000CZ0RK6&asins=B000CZ0RK6&linkId=9ab577382379bf1ccb3e4491823057a1&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 
const iframeKorgMIDIController = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=loopstarapp-20&marketplace=amazon&amp;region=US&placement=B01AT2ATYU&asins=B01AT2ATYU&linkId=14b890f6fe6cc36cd658cd0b1153a324&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 

function IframeIAdapt(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeIAdapt?props.iframeIAdapt:""}} />);
}

function IframeIAdapt2(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeIAdapt2?props.iframeIAdapt2:""}} />);
}

function IframeMovoXLR(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeMovoXLR?props.iframeMovoXLR:""}} />);
}

function Iframe2Oct(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe2Oct?props.iframe2Oct:""}} />);
}

function IframeiRigHD2(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeiRigHD2?props.iframeiRigHD2:""}} />);
}

function IframeSmartRig(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeSmartRig?props.iframeSmartRig:""}} />);
}

function IframeiRigBlueBoard(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeiRigBlueBoard?props.iframeiRigBlueBoard:""}} />);
}

function IframeWirelessBTAdapterBMA01(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeWirelessBTAdapterBMA01?props.iframeWirelessBTAdapterBMA01:""}} />);
}


function IframeBehringerFCB1010(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeBehringerFCB1010?props.iframeBehringerFCB1010:""}} />);
}


function IframeKorgMIDIController(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframeKorgMIDIController?props.iframeKorgMIDIController:""}} />);
}

const Gear = () => (
    <div className='gear'>
      <h1>Audio Equipment that has been tested with LoopStar</h1>
      <header className="App-header">
        <p>
          LoopStar Gear Info &nbsp;&nbsp;
          <p></p> 
        &nbsp;&nbsp;
        </p>
        <p></p>
        
      </header>
      <body className="App-subHeader">
      <p><a>What gear is good to use with LoopStar?</a></p>
        </body>
        <text className="App-text">
        <a> You can use the LoopStar.App on your iPhone or iPad without a lot of equipment but for more options here is a list of gear that's been tested so far.</a>
        <p></p>
        <p>&nbsp;</p>
        <h1>Audio Input Devices: </h1>
        <p>&nbsp;</p>
        <h4>Saramonic SmartRig Plus interface</h4>
        <a> Get audio into your iPhone / iPad: </a>
        <IframeSmartRig iframeSmartRig={iframeSmartRig} />
        <a> Plug your guitar, bass, keyboard or other instrument into the Saramonic SmartRig Plus and record your loop tracks with LoopStar! </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Movo TCB2 XLR Microphone to TRRS adapter to 3.5mm Splitter</h4>
        <a> Get audio into your iPhone / iPad with a very inexpensive interface: </a>
        <IframeMovoXLR iframeMovoXLR={iframeMovoXLR} />
        <a> The Movo TCB2 XLR, is an excellent option for getting instrument audio into your iOS device! </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Interface Adapter Converter Link for iPhone/iPad</h4>
        <a> Other iPhone / iPad interfaces: </a>
        <IframeIAdapt iframeIAdapt={iframeIAdapt} />
        <IframeIAdapt2 iframeIAdapt2={iframeIAdapt2} />
        <a>  </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h4>IK Multimedia iRig HD 2 digital guitar interface</h4>
        <a> Get audio into your iPhone / iPad: </a>
        <IframeiRigHD2 iframeiRigHD2={iframeiRigHD2} />
        <a> Plug your guitar, bass, keyboard or other instrument into the iRig and record your loop tracks with LoopStar! </a>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>MIDI Controller Devices: </h1>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>IK Multimedia iRig Blueboard Wireless Floor Controller for iOS and Mac</h4>
        <a> With LoopStar, turn your iPhone / iPad into a Loop Pedal: </a>
        <IframeiRigBlueBoard iframeiRigBlueBoard={iframeiRigBlueBoard} />
        <a> Use the iRig BlueBoard for a compact and portable Loop Pedal, set your hands free to record your loop tracks with LoopStar! </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h4>With the the Wireless BM-A01 Bluetooth MIDI Adapter, convert your MIDI Device into a MIDI wireless device for you iPhone/iPad</h4>
        <a> And stream MIDI commands into LoopStar: </a>
        <IframeWirelessBTAdapterBMA01 iframeWirelessBTAdapterBMA01={iframeWirelessBTAdapterBMA01} />
        <a> Use the Wireless BM-A01 Bluetooth MIDI Adapter 5-PIN DIN Interface Converter for iPhone, iPod Touch, iPad, Mac! </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Combined with the BM-A01 Bluetooth wireless MIDI Adapter, the Behringer FCB1010 is the ultimate foot pedal for your loops, sequencer controls, effects preset selection and effects channel control.</h4>
        <a> The Behringer FCB1010 and LoopStar makes for a powerful configuation!</a>
        <IframeBehringerFCB1010 iframeBehringerFCB1010={iframeBehringerFCB1010} />
        <a> Use the Behringer FCB1010 Ultra-Flexible MIDI Foot Controller with 2 Expression Pedals and MIDI Merge Function,Slvr/Blk </a>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>With the Korg Midi Controller (NANOKONST), Live Mix your Loops with LoopStar</h4>
        <a> You can adjust volume and panning, stop/start tracks, and mix effects into your mix! </a>
        <IframeKorgMIDIController iframeKorgMIDIController={iframeKorgMIDIController} />
        <a> With LoopStar and your iPhone/iPad, use the Korg Wireless MIDI controller as your mixing console. </a>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p></p>
        <p></p>
        <h1>External Audio effects that can make your looping more diverse: </h1>
        <p>&nbsp;</p>
        <h4>Guitar Octive Pedal</h4>
        <a> The VSN Precise Octive pedal can quickly add some bass to your mix:  </a>
        <Iframe2Oct iframe2Oct={iframe2Oct} />
        <a>
          I tried this VSN Precise Octive pedal and for the price it has worked pretty well!  
          It allows me to quickly add some interesting bottom end to my loop session and I don't even need to bother plugging in a Bass Guitar.     
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        
        <p></p>
        <p></p>
        <p></p>
        </a>
        </text>
       
    </div>
  );

  export default Gear;